import { ComponentProps, HTMLAttributes } from "react";

interface ImgproxyLoaderProps {
  src: string;
  width?: number;
  height?: number;
  quality?: number;
}

export function imgproxyLoader({ src, width, height, quality }: ImgproxyLoaderProps) {
  const path =
    `/size:${width ? width : 0}:${height ? height : 0}` +
    `/resizing_type:fill` +
    (quality ? `/quality:${quality}` : "") +
    `/sharpen:0.5` +
    `/plain/${src}` +
    `@webp`;

  // only do this for dev
  // return src;

  const host = "https://imgproxy.antlur.co";

  const imgUrl = `${host}/insecure${path}`;

  return imgUrl;
}

// make an image component that uses imgproxyLoader

interface ImageProps extends ComponentProps<"img"> {
  src: string;
  alt: string;
  width?: number;
  height?: number;
  quality?: number;
  className?: string;
}

export function Image({ src, alt, width = 0, height = 0, quality = 70, className = "", ...props }: ImageProps) {
  const imgSrc = imgproxyLoader({ src, width, height, quality });

  return <img src={imgSrc} alt={alt} className={className} {...props} />;
}
